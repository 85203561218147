import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './ICPTester.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { fetchFromApi } from '../utils/api';
import { wait } from '@testing-library/user-event/dist/utils';

const defaultSystemPrompt = `Your task is to rate companies based on their Ideal Customer Profile (ICP) fit.
The rating scale ranges from 0 to 100, where 0 indicates a poor fit and 100 indicates an excellent fit.
You will be provided with the name of the company seeking customers along with a short description.
You will be provided a short description of the ideal customer profile for the company.
You will be provided a short description of a bad customer profile for the company.
Additionally, you will be given a list of example target companies, their ratings, and explanations for their ratings.
Based on your knowledge of these companies and the provided information, rate a given target company.
Return a JSON object containing the rating for the target company.
Example return object: {"company": "Company A", "rating": 0, "explanation": "They are too large."}
`;

const defaultPrompt = `We are: {mycompany}

What makes us unique:
{mycompany_description}

What makes an ideal customer:
{mycompany_ideal_customer}

What kind of customers should be avoided:
{mycompany_bad_customer}

Examples of how we rate potential customers:
{examples}

Rate the following company based on their Ideal Customer Profile (ICP) fit:
{company}
`

const defaultDiscoverySystemPrompt = `Your task is to generate list of company names based on Ideal Customer Profile (ICP).
The ICP rating scale ranges from 0 to 100, where 0 indicates a poor fit and 100 indicates an excellent fit.
You will be provided with the name of the company seeking customers along with a short description.
You will be provided a short description of the ideal customer profile for the company.
You will be provided a short description of a bad customer profile for the company.
Additionally, you will be given a list of example target companies, their ratings, and explanations for their ratings.
Based on your knowledge of these companies and the provided information, generate a list of 10 target companies which would get an excellent ICP rating.
Do not list any companies that are already in the example list.
Return a JSON object containing an array of company names.
Only include the company name in the array, nothing else.
Example return object: {"results": ["Company A", "Company B", "Company C"]}
`;

const defaultDiscoveryPrompt = `We are: {mycompany}

What makes us unique:
{mycompany_description}

What makes an ideal customer:
{mycompany_ideal_customer}

What kind of customers should be avoided:
{mycompany_bad_customer}

Examples of how we rate potential customers:
{examples}

You have already generated the following target companies for us:
{companies}

Please generate 10 more target companies which would get an excellent ICP rating.
`

interface ExampleRating {
  company: string;
  rating: number;
  explanation: string;
}

function scrollToBottom() {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth' // Optional: this makes the scroll smooth
  });
}

const ICPTester = () => {
  // For testing!

  // const [companyName, setCompanyName] = useState('Nokia');
  // const [companyDescription, setCompanyDescription] = useState('We make mobile phones');
  // const [goodCustomerDescription, setGoodCustomerDescription] = useState('Carriers with more than 100 million subscribers');
  // const [badCustomerDescription, setBadCustomerDescription] = useState('B2C companies');
  // const [exampleCompanies, setExampleCompanies] = useState<ExampleRating[]>([
  //   { company: 'Salesforce', rating: 0, explanation: 'They are not even a carrier' },
  //   { company: 'Tello', rating: 25, explanation: 'Too small of a carrier for us' },
  //   { company: 'AT&T', rating: 100, explanation: 'Just perfect!' }
  // ]);
  // const [toBeScoredCompanies, setToBeScoredCompanies] = useState('Visible, US Mobile, Alibaba, Verizon');

  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [goodCustomerDescription, setGoodCustomerDescription] = useState('');
  const [badCustomerDescription, setBadCustomerDescription] = useState('');
  const [exampleCompanies, setExampleCompanies] = useState<ExampleRating[]>([]);
  const [toBeScoredCompanies, setToBeScoredCompanies] = useState('');

  const [systemPrompt, setSystemPrompt] = useState(defaultSystemPrompt);
  const [prompt, setPrompt] = useState(defaultPrompt);
  const [discoverySystemPrompt, setDiscoverySystemPrompt] = useState(defaultDiscoverySystemPrompt);
  const [discoveryPrompt, setDiscoveryPrompt] = useState(defaultDiscoveryPrompt);
  const [scores, setScores] = useState<any[]>([]);

  const [showSystemPrompt, setShowSystemPrompt] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isScoring, setIsScoring] = useState(false);
  const [isDiscovering, setIsDiscovering] = useState(false);
  const [error, setError] = useState('');
  const [justGenerated, setJustGenerated] = useState(false);
  const [shouldSaveData, setShouldSaveData] = useState(false);

  const navigate = useNavigate();
  const { guid } = useParams();

  useEffect(() => {
    if (!guid) {
      // GUID not in URL, generate and navigate
      const newGuid = generateGuid();
      setJustGenerated(true); // Mark as just generated
      navigate(`/icp/${newGuid}`);
    } else {
      // GUID is present, attempt to load data
      setJustGenerated(false); // Reset flag in case component is reused
      loadData(guid);
    }
  }, [guid, navigate]);

  useEffect(() => {
    if (scores && scores.length > 0) { // Assuming scores is your state variable holding the results
      scrollToBottom();
    }
  }, [scores]); // Dependency array, this effect runs every time `scores` updates

  useEffect(() => {
    if (shouldSaveData && guid) {
      saveData(guid);
      setShouldSaveData(false);
    }
  }, [shouldSaveData, guid]); // Depend on shouldSaveData flag and guid

  function generateGuid() {
    const now = new Date();
    const timestamp = now.toISOString().replace(/[^0-9]/g, '').slice(0, 8); // yyyymmddhhss format
    const randomString = Math.random().toString(36).substring(2, 10); // 8 character random string
    return `${timestamp}-${randomString}`;
  }

  async function score(guid: string) {
    saveData(guid);

    const companiesList = toBeScoredCompanies.split(',').map(company => company.trim());
    const scoringPromises = companiesList.map(company => {
      const finalPrompt = prompt
        .replace('{mycompany}', companyName)
        .replace('{mycompany_description}', companyDescription)
        .replace('{mycompany_ideal_customer}', goodCustomerDescription)
        .replace('{mycompany_bad_customer}', badCustomerDescription)
        .replace('{examples}', exampleCompanies.map((example) => `${example.company} - Rating: ${example.rating} - ${example.explanation}`).join('\n'))
        .replace('{company}', company);
      console.log(finalPrompt);
      return fetchFromApi(`/gpt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          system: systemPrompt,
          prompt: finalPrompt,
          response_format: 'json_object'
        })
      }).then(response => response.json())
        .then(data => ({ company: company, rating: data.result.rating, explanation: data.result.explanation }))
        .catch(error => ({ company: company, rating: 0, explanation: error.toString() }));
    });

    setIsScoring(true);

    try {
      const results = await Promise.all(scoringPromises);
      setScores(results);
    } catch (error) {
      console.error("An error occurred while scoring companies:", error);
    } finally {
      setIsScoring(false);
    }
  }

  async function discovery(guid: string) {
    setIsDiscovering(true);

    const companiesList = (toBeScoredCompanies || '').split(',')
                       .map(company => company.trim())
                       .filter(company => company.length > 0);

    const finalPrompt = discoveryPrompt
      .replace('{mycompany}', companyName)
      .replace('{mycompany_description}', companyDescription)
      .replace('{mycompany_ideal_customer}', goodCustomerDescription)
      .replace('{mycompany_bad_customer}', badCustomerDescription)
      .replace('{examples}', exampleCompanies.map((example) => `${example.company} - Rating: ${example.rating} - ${example.explanation}`).join('\n'))
      .replace('{companies}', companiesList.join(', '));
    console.log(finalPrompt);

    await fetchFromApi(`/gpt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        system: discoverySystemPrompt,
        prompt: finalPrompt,
        response_format: 'json_object'
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // join companiesList with the result
        setToBeScoredCompanies([...companiesList, ...data.result.results].join(', '))

        //setToBeScoredCompanies(`${toBeScoredCompanies}, ${data.result.join(', ')}`);
        setIsDiscovering(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to discover companies');
        setIsDiscovering(false);
      });
  }

  async function saveData(guid: string) {
    console.log(`Saving data for GUID: ${guid}`);
    await fetchFromApi(`/icp/${guid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        companyName: companyName,
        companyDescription: companyDescription,
        goodCustomerDescription: goodCustomerDescription,
        badCustomerDescription: badCustomerDescription,
        exampleCompanies: exampleCompanies,
        toBeScoredCompanies: toBeScoredCompanies,
        systemPrompt: systemPrompt,
        prompt: prompt,
        scores: scores
      })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to save data');
      });
  }

  // Function to load data from an API using the GUID
  async function loadData(guid: string) {
    if (justGenerated) {
      return;
    }
    setIsFetching(true);
    console.log(`Loading data for GUID: ${guid}`);
    await fetchFromApi(`/icp/${guid}`)
      .then((response) => response.json())
      .then((data) => {
        setCompanyName(data.companyName);
        setCompanyDescription(data.companyDescription);
        setGoodCustomerDescription(data.goodCustomerDescription);
        setBadCustomerDescription(data.badCustomerDescription);
        setExampleCompanies(data.exampleCompanies || []);
        setToBeScoredCompanies(data.toBeScoredCompanies);
        setSystemPrompt(data.systemPrompt || defaultSystemPrompt);
        setPrompt(data.prompt || defaultPrompt);
        setScores(data.scores);
        setIsFetching(false);
      })
      .catch((error) => {
        console.log('ERROR');
        console.error(error);
        setError('Failed to fetch data');
        setIsFetching(false);
      });
  }

  return (
    <header className="App-header text-center">
      <h1 className={styles.title}><img src="/logo512.png" width={36} style={{ position: 'relative', top: '-4px' }} /> ICP Tester</h1>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-9">
            <div className={`${styles.inputSection}`}>
              <h3>My company</h3>
              <input
                type="text"
                className={`form-control ${styles.textInputCustom}`}
                placeholder={!isFetching ? "Nokia" : "Loading..."}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className={`${styles.inputSection} mt-3`}>
              <h3>What makes you unique?</h3>
              <textarea
                className={`form-control ${styles.textInputCustom}`}
                placeholder={!isFetching ? "We are the leading provider of network infrastructure." : "Loading..."}
                value={companyDescription}
                rows={3}
                onChange={(e) => setCompanyDescription(e.target.value)}
              />
            </div>
            <div className={`${styles.inputSection} mt-3`}>
              <h3>What makes an ideal customer?</h3>
              <textarea
                className={`form-control ${styles.textInputCustom}`}
                placeholder={!isFetching ? "More than 1000 employees, well-funded company with a product-market fit." : "Loading..."}
                value={goodCustomerDescription}
                rows={3}
                onChange={(e) => setGoodCustomerDescription(e.target.value)}
              />
            </div>
            <div className={`${styles.inputSection} mt-3`}>
              <h3>What kind of customers should be avoided?</h3>
              <textarea
                className={`form-control ${styles.textInputCustom}`}
                placeholder={!isFetching ? "Less than 100 employees, no product-market fit, still looking for a series A funding." : "Loading..."}
                value={badCustomerDescription}
                rows={3}
                onChange={(e) => setBadCustomerDescription(e.target.value)}
              />
            </div>
            <div className={`${styles.inputSection} mt-3`}>
              <h3>Example ratings</h3>
              <table>
                <tbody>
                  {(exampleCompanies || []).map((example, index) => (
                    <tr key={index}>
                      <td style={{ width: '200px' }}>
                        <input
                          type="text"
                          className={`form-control ${styles.textInputCustom}`}
                          placeholder={!isFetching ? "Salesforce" : "Loading..."}
                          value={example.company}
                          onChange={(e) => {
                            const newExampleCompanies = [...exampleCompanies];
                            newExampleCompanies[index].company = e.target.value;
                            setExampleCompanies(newExampleCompanies);
                          }}
                        />
                      </td>
                      <td style={{ width: '130px' }}>
                        <select
                          className={`form-select ${styles.textInputCustom}`}
                          value={example.rating}
                          onChange={(e) => {
                            const newExampleCompanies = [...exampleCompanies];
                            newExampleCompanies[index].rating = parseInt(e.target.value);
                            setExampleCompanies(newExampleCompanies);
                          }}
                        >
                          <option value={0}>Very Bad</option>
                          <option value={25}>Poor</option>
                          <option value={50}>Neutral</option>
                          <option value={75}>Good</option>
                          <option value={100}>Great</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className={`form-control ${styles.textInputCustom}`}
                          placeholder={!isFetching ? "They are too large" : "Loading..."}
                          value={example.explanation}
                          onChange={(e) => {
                            const newExampleCompanies = [...exampleCompanies];
                            newExampleCompanies[index].explanation = e.target.value;
                            setExampleCompanies(newExampleCompanies);
                          }}
                        />
                      </td>
                      <td style={{ width: '30px' }}>
                        <button className="btn btn-outline-danger" onClick={() => {
                          const newExampleCompanies = [...exampleCompanies];
                          newExampleCompanies.splice(index, 1);
                          setExampleCompanies(newExampleCompanies);
                        }}>
                          <i className="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className="btn btn-outline-success" onClick={() => {
                setExampleCompanies([...exampleCompanies, { company: '', rating: 0, explanation: '' }]);
              }
              }><i className="bi bi-person-plus-fill" style={{ marginRight: '8px' }}></i>Create</button>
            </div>
            <hr />
            <div className={`${styles.inputSection}`}>
              <h3 className="d-inline-block me-2">System Prompt</h3>
              <a href="#" className="btn btn-link p-0" onClick={(e) => { e.preventDefault(); setShowSystemPrompt(!showSystemPrompt); }}>
                {showSystemPrompt ? 'Hide' : 'Show'}
              </a>
              {showSystemPrompt && (
                <textarea
                  className={`form-control ${styles.promptInput}`}
                  placeholder={!isFetching ? "We are the leading provider of network infrastructure." : "Loading..."}
                  value={systemPrompt}
                  rows={20}
                  onChange={(e) => setSystemPrompt(e.target.value)}
                />
              )}
            </div>
            <div className={`${styles.inputSection}`}>
              <h3 className="d-inline-block me-2">Prompt</h3>
              <a href="#" className="btn btn-link p-0" onClick={(e) => { e.preventDefault(); setShowPrompt(!showPrompt); }}>
                {showPrompt ? 'Hide' : 'Show'}
              </a>
              {showPrompt && (
                <textarea
                  className={`form-control ${styles.promptInput}`}
                  placeholder={!isFetching ? "We are the leading provider of network infrastructure." : "Loading..."}
                  value={prompt}
                  rows={20}
                  onChange={(e) => setPrompt(e.target.value)}
                />
              )}
            </div>
            <hr />
            <div className={`${styles.inputSection} mt-3`}>
              <h3>List of companies to be analysed
                <button
                  className="btn btn-primary btn-sm"
                  onClick={async () => {
                    if (guid) {
                      discovery(guid);
                    } else {
                      console.error('No guid!');
                      setError('No guid!');
                    }
                  }}
                  style={{marginLeft: '10px'}}
                >
                  {isDiscovering ?
                    <div className="spinner-border" style={{ width: '16px', height: '16px', marginRight: '8px' }}></div> :
                    <i className="bi bi-robot" style={{ marginRight: '8px' }}></i>}
                    Generate
                </button>                
              </h3>
              <textarea
                className={`form-control ${styles.textInputCustom}`}
                placeholder={!isFetching ? "Spotify, Reddit, Alibaba" : "Loading..."}
                value={toBeScoredCompanies}
                rows={3}
                onChange={(e) => setToBeScoredCompanies(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={async () => {
                  if (guid) {
                    setIsScoring(true);
                    await score(guid);
                    setIsScoring(false);
                  } else {
                    console.error('No guid!');
                    setError('No guid!');
                  }
                }}
              >
                {isScoring ?
                  <div className="spinner-border" style={{ width: '16px', height: '16px', marginRight: '8px' }}></div> :
                  <i className="bi bi-robot" style={{ marginRight: '8px' }}></i>}
                Analyse
              </button>
            </div>
            <div style={{ minHeight: '50px' }} className={`${styles.inputSection} mt-3`}>
              {error && (
                <div className="invalid-feedback" style={{ display: 'block' }}>{error}</div>
              )}
            </div>
            {scores && scores.length > 0 && (
              <div className={`${styles.inputSection} mt-3 mb-5`}>
                <h3>Results</h3>
                <table>
                  <tbody>
                    {scores.sort((a, b) => b.rating - a.rating).map((score, index) => (
                      <tr key={index}>
                        <td style={{
                          textAlign: 'left',
                          verticalAlign: 'top',
                          padding: '15px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap', // Prevent text wrapping
                          minWidth: '150px' // Adjust as needed to give more room
                        }}>
                          {score.company}
                        </td>
                        <td style={{ textAlign: 'right', padding: '15px', verticalAlign: 'top' }}>
                          {score.rating}
                        </td>
                        <td style={{ textAlign: 'left', verticalAlign: 'top', padding: '15px' }}>
                          {score.explanation}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default ICPTester;
