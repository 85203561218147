import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './ICPMerger.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { fetchFromApi } from '../utils/api';
import { wait } from '@testing-library/user-event/dist/utils';

const ICPMerger = () => {
  const [companies, setCompanies] = useState('');
  const [icpProfile, setIcpProfile] = useState<any>({});
  const [gptLogs, setGptLogs] = useState<any>({});
  const [modalContent, setModalContent] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function handleClick() {
    if (companies === '') {
      setError('Company name cannot be empty');
      return;
    }
    setError('');
    setGptLogs({});
    setIsFetching(true);
    await fetchApiData('/company/icp/merge', companies);
    setIsFetching(false);
  }

  async function fetchApiData(apiUrl: string, companies: string) {
    try {
      const response = await fetchFromApi(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ companies: companies })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data);
      setGptLogs(data.logs);
      setIcpProfile(data.result);
    } catch (error) {
      console.error(error);
      setError('Failed to fetch data');
    }
  }


  function handleLogClick(name: string) {
    setModalContent(gptLogs);
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <header className="App-header text-center">
      <h1 className={styles.title}><img src="/logo512.png" width={36} style={{ position: 'relative', top: '-4px' }} /> ICP Merger</h1>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-9">
            <div className={`input-group mt-3 ${styles.inputGroup}`}>
              <input
                type="text"
                className={`form-control ${styles.textInputCustom}`}
                placeholder={"Salesforce, Spotify, Paypal"}
                aria-label="Meetup URL"
                aria-describedby="button-addon2"
                value={companies}
                onChange={(e) => setCompanies(e.target.value)}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={handleClick}
              >
                Merge
              </button>
            </div>
            <div style={{ minHeight: '50px' }}>
              {error && (
                <div className="invalid-feedback" style={{ display: 'block' }}>{error}</div>
              )}
            </div>
            <table>
              <tbody>
                {Object.keys(icpProfile).sort().map((key) => (
                  <tr key={key}>
                    <td style={{ textAlign: 'left', verticalAlign: 'top', padding: '15px', fontWeight: 'bold' }}>{key}</td>
                    <td style={{ textAlign: 'right', padding: '15px', verticalAlign: 'top' }}>
                      <button className="btn btn-outline-secondary" onClick={() => handleLogClick(key)}>LOG</button>
                    </td>
                    <td style={{ textAlign: 'left', verticalAlign: 'top', padding: '15px' }}>{icpProfile[key]}</td>
                  </tr>
                ))}
                {isFetching && (
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'center' }}>
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalDialog}>
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                <h5 className={styles.modalTitle}>GPT Log</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className={styles.modalBody}>
                <table>
                  <tbody>
                    {Object.keys(modalContent).map((key) => (
                      <tr key={key}>
                        <td style={{ textAlign: 'left', verticalAlign: 'top', paddingRight: '20px' }}>{key}</td>
                        <td style={{ textAlign: 'left', verticalAlign: 'top', paddingBottom: '10px' }}>
                          {modalContent[key].split('\n').map((line: string, index: number) => (
                            <React.Fragment key={index}>
                              {line}
                              <br /><br />
                            </React.Fragment>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default ICPMerger;
