import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './ICP.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchFromApi } from '../utils/api';


const systemPrompt = `Your task is to rate companies based on their Ideal Customer Profile (ICP) fit.
The rating scale ranges from 0 to 99, where 0 indicates a poor fit and 99 indicates an excellent fit.
You will be provided with the name of the company seeking customers along with a short description.
You will be provided a short description of the ideal customer profile for the company.
You will be provided a short description of a bad customer profile for the company.
Additionally, you will be given a list of example target companies, their ratings, and explanations for their ratings.
Based on your knowledge of these companies and the provided information, rate a given target company.
Keep the explanations to 240 characters and focus on why this company matches with the information provided.
Return a JSON object containing the rating for the target company and their company website domain.
Example return object: {"company": "Amazon", "domain": "amazon.com", "rating": 25, "explanation": "They are too large."}
`;

const prompt = `We are: {mycompany}

What makes us unique:
{mycompany_description}

What makes an ideal customer:
{mycompany_ideal_customer}

What kind of customers should be avoided:
{mycompany_bad_customer}

Examples of how we rate potential customers:
{examples}

Rate the following company based on their Ideal Customer Profile (ICP) fit:
{company}
`

const discoverySystemPrompt = `Your task is to generate list of company names based on Ideal Customer Profile (ICP).
The ICP rating scale ranges from 0 to 99, where 0 indicates a poor fit and 99 indicates an excellent fit.
You will be provided with the name of the company seeking customers along with a short description.
You will be provided a short description of the ideal customer profile for the company.
You will be provided a short description of a bad customer profile for the company.
Additionally, you will be given a list of example target companies, their ratings, and explanations for their ratings.
Based on your knowledge of these companies and the provided information, generate a list of 3 target companies.
The target companies should have an excellent ICP rating based on the information provided.
Do not list any companies that are already in the example list.
Try not to list any companies with a poor rating.
Return a JSON object containing an array of company names.
Only include the company name in the array, nothing else.
Example return object: {"results": ["Company A", "Company B", "Company C"]}
`;

const discoveryPrompt = `We are: {mycompany}

What makes us unique:
{mycompany_description}

What makes an ideal customer:
{mycompany_ideal_customer}

What kind of customers should be avoided:
{mycompany_bad_customer}

Examples of how we rate potential customers:
{examples}

You have already generated the following target companies for us:
{companies}

Please generate 3 more target companies which would get an excellent ICP rating.
`

interface ExampleRating {
  id: number;
  company: string;
  rating: number;
  explanation: string;
}

function generateGuid() {
  return Math.random().toString(36).substring(2, 12); // 8 character random string
}

const getColorForRating = (rating: number) => {
  // Red to Green gradient with lighter shades
  const red = 224 - (64 * (rating / 99)); // Decrease red as rating increases
  const green = 190 + 40 * (rating / 99);       // Increase green as rating increases

  // Ensure RGB values stay within the 0-255 range
  const finalRed = Math.min(255, red);
  const finalGreen = Math.min(255, green);
  const finalBlue = 160; // Adding more white to the mix

  return `rgb(${Math.round(finalRed)}, ${Math.round(finalGreen)}, ${finalBlue})`;
};

const defaultExampleCompany: ExampleRating = { id: 1, company: '', rating: 75, explanation: '' };

const ICP: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeTab, setActiveTab] = useState('profile');

  // const [companyName, setCompanyName] = useState('Nokia');
  // const [companyDescription, setCompanyDescription] = useState('We make mobile phones');
  // const [goodCustomerDescription, setGoodCustomerDescription] = useState('Carriers with more than 100 million subscribers');
  // const [badCustomerDescription, setBadCustomerDescription] = useState('B2C companies');
  // const [exampleCompanies, setExampleCompanies] = useState<ExampleRating[]>([
  //   { id: 1, company: 'Salesforce', rating: 0, explanation: 'They are not even a carrier' },
  //   { id: 2, company: 'Tello', rating: 25, explanation: 'Too small of a carrier for us' },
  //   { id: 3, company: 'AT&T', rating: 100, explanation: 'Just perfect!' }
  // ]);
  // const [toBeScoredCompanies, setToBeScoredCompanies] = useState('Visible, US Mobile, Alibaba, Verizon');

  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [goodCustomerDescription, setGoodCustomerDescription] = useState('');
  const [badCustomerDescription, setBadCustomerDescription] = useState('');
  const [exampleCompanies, setExampleCompanies] = useState<ExampleRating[]>([defaultExampleCompany]);

  const [toBeScoredCompanies, setToBeScoredCompanies] = useState('');
  const [scores, setScores] = useState<any[]>([]);
  const [recentlyScored, setRecentlyScored] = useState<string[]>([]);

  const [isFetching, setIsFetching] = useState(false);
  const [isScoring, setIsScoring] = useState(false);
  const [isDiscovering, setIsDiscovering] = useState(false);
  const [error, setError] = useState('');
  const [justGenerated, setJustGenerated] = useState(false);
  const [shouldSaveData, setShouldSaveData] = useState(false);
  const [isProfileEdited, setIsProfileEdited] = useState(false);

  const navigate = useNavigate();
  const { guid } = useParams();

  useEffect(() => {
    if (shouldSaveData && guid) {
      saveData(guid);
      setShouldSaveData(false);
    }
  }, [shouldSaveData, guid]); // Depend on shouldSaveData flag and guid

  useEffect(() => {
    if (!guid) {
      // GUID not in URL, generate and navigate
      const newGuid = generateGuid();
      setJustGenerated(true); // Mark as just generated
      navigate(`/profile/${newGuid}`);
    } else {
      // GUID is present, attempt to load data
      setJustGenerated(false); // Reset flag in case component is reused
      loadData(guid);
    }
  }, [guid, navigate]);

  async function score(companiesList: string[] = [], incremental: boolean) {
    const scoringPromises = companiesList.map(company => {
      const finalPrompt = prompt
        .replace('{mycompany}', companyName)
        .replace('{mycompany_description}', companyDescription)
        .replace('{mycompany_ideal_customer}', goodCustomerDescription)
        .replace('{mycompany_bad_customer}', badCustomerDescription)
        .replace('{examples}', exampleCompanies.map((example) => `${example.company} - Rating: ${example.rating} - ${example.explanation}`).join('\n'))
        .replace('{company}', company);
      console.log(systemPrompt);
      return fetchFromApi(`/gpt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          system: systemPrompt,
          prompt: finalPrompt,
          response_format: 'json_object'
        })
      }).then(response => response.json())
        .then(data => ({ company: company, domain: data.result.domain, rating: data.result.rating, explanation: data.result.explanation }))
        .catch(error => ({ company: company, rating: 0, explanation: error.toString() }));
    });

    setIsScoring(true);

    try {
      const results = await Promise.all(scoringPromises);
      console.log(results);
      if(incremental) {
        const oldScores = scores.filter((score) => !companiesList.includes(score.company));
        setScores([...oldScores, ...results]);
      } else {
        setScores(results);
      }
      setRecentlyScored(results.map((score) => score.company));
      setShouldSaveData(true);
    } catch (error) {
      console.error("An error occurred while scoring companies:", error);
    } finally {
      setIsScoring(false);
    }
  }

  async function discovery(guid: string) {
    setIsDiscovering(true);

    const companiesList = (toBeScoredCompanies || '').split(',')
      .map(company => company.trim())
      .filter(company => company.length > 0);

    const finalPrompt = discoveryPrompt
      .replace('{mycompany}', companyName)
      .replace('{mycompany_description}', companyDescription)
      .replace('{mycompany_ideal_customer}', goodCustomerDescription)
      .replace('{mycompany_bad_customer}', badCustomerDescription)
      .replace('{examples}', exampleCompanies.map((example) => `${example.company} - Rating: ${example.rating} - ${example.explanation}`).join('\n'))
      .replace('{companies}', [...scores.map((score) => score.company), ...companiesList].join(', '));
    console.log(finalPrompt);

    await fetchFromApi(`/gpt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        system: discoverySystemPrompt,
        prompt: finalPrompt,
        response_format: 'json_object'
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // join companiesList with the result
        //setToBeScoredCompanies([...companiesList, ...data.result.results].join(', '))
        setToBeScoredCompanies([...data.result.results].join(', '))

        //setToBeScoredCompanies(`${toBeScoredCompanies}, ${data.result.join(', ')}`);
        setIsDiscovering(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to discover companies');
        setIsDiscovering(false);
      });
  }

  async function saveData(guid: string) {
    await fetchFromApi(`/icp/${guid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        companyName: companyName,
        companyDescription: companyDescription,
        goodCustomerDescription: goodCustomerDescription,
        badCustomerDescription: badCustomerDescription,
        exampleCompanies: exampleCompanies,
        toBeScoredCompanies: toBeScoredCompanies,
        scores: scores
      })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to save data');
      });
  }

  // Function to load data from an API using the GUID
  async function loadData(guid: string) {
    if (justGenerated) {
      return;
    }
    setIsFetching(true);
    console.log(`Loading data for GUID: ${guid}`);
    await fetchFromApi(`/icp/${guid}`)
      .then((response) => response.json())
      .then((data) => {
        setCompanyName(data.companyName);
        setCompanyDescription(data.companyDescription);
        setGoodCustomerDescription(data.goodCustomerDescription);
        setBadCustomerDescription(data.badCustomerDescription);
        setExampleCompanies(data.exampleCompanies || [defaultExampleCompany]);
        setScores(data.scores || []);
        setIsFetching(false);
      })
      .catch((error) => {
        console.log('ERROR');
        console.error(error);
        setError('Failed to fetch data');
        setIsFetching(false);
      });
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleRemove = (id: number) => {
    setExampleCompanies(exampleCompanies.filter(rating => rating.id !== id));
  };

  const handleAdd = () => {
    setExampleCompanies([...exampleCompanies, { id: Date.now(), company: '', rating: 50, explanation: '' }]);
  };

  const handleRatingChange = (event: ChangeEvent<HTMLSelectElement>, id: number) => {
    setIsProfileEdited(true);
    const newRatings = exampleCompanies.map(rating => {
      if (rating.id === id) {
        return { ...rating, rating: parseInt(event.target.value) };
      }
      return rating;
    });
    setExampleCompanies(newRatings);
  };

  const handleCompanyChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    setIsProfileEdited(true);
    const newRatings = exampleCompanies.map(rating => {
      if (rating.id === id) {
        return { ...rating, company: event.target.value };
      }
      return rating;
    });
    setExampleCompanies(newRatings);
  };

  const handleExplanationChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    setIsProfileEdited(true);
    const newRatings = exampleCompanies.map(rating => {
      if (rating.id === id) {
        return { ...rating, explanation: event.target.value };
      }
      return rating;
    });
    setExampleCompanies(newRatings);
  };

  return (
    <div>
      <div className={styles.topHeader}>
        <img src="/logo512.png" alt="Leadray Logo" className={styles.logo} />
        Leadray
      </div>
      <div className={styles.subHeader}>
        Ideal Customer Profile <span style={{ color: '#333', marginLeft: '20px' }}>{companyName}</span>
      </div>
      <div className={styles.content}>
        {windowWidth <= 768 ? (
          <div className={`${styles.tabs} nav nav-tabs`}>
            <button onClick={() => setActiveTab('profile')} className={activeTab === 'profile' ? 'nav-link active' : `nav-link ${styles.deactiveTab}`}>Profile</button>
            <button onClick={() => setActiveTab('ratings')} className={activeTab === 'ratings' ? 'nav-link active' : `nav-link ${styles.deactiveTab}`}>Ratings</button>
          </div>
        ) : null}
        <div className={styles.section} style={{ display: activeTab === 'profile' || window.innerWidth > 768 ? 'block' : 'none' }}>
          <div className={styles.profileSection}>
            <div className={styles.field}>
              <label>My Company</label>
              <input type="text" value={companyName} placeholder={!isFetching ? "Nokia" : "Loading..."} onChange={(e) => { setIsProfileEdited(true); setCompanyName(e.target.value) }} />
            </div>
            <div className={styles.field}>
              <label>What makes you unique?</label>
              <textarea value={companyDescription} placeholder={!isFetching ? "We are the leading provider of network infrastructure." : "Loading..."} onChange={(e) => { setIsProfileEdited(true); setCompanyDescription(e.target.value) }}></textarea>
            </div>
            <div className={styles.field}>
              <label>What makes an ideal customer?</label>
              <textarea value={goodCustomerDescription} placeholder={!isFetching ? "More than 1000 employees, well-funded company with a product-market fit." : "Loading..."} onChange={(e) => { setIsProfileEdited(true); setGoodCustomerDescription(e.target.value) }}></textarea>
            </div>
            <div className={styles.field}>
              <label>What kind of customers should be avoided?</label>
              <textarea value={badCustomerDescription} placeholder={!isFetching ? "Less than 100 employees, no product-market fit, still looking for a series A funding." : "Loading..."} onChange={(e) => { setIsProfileEdited(true); setBadCustomerDescription(e.target.value) }}></textarea>
            </div>
            <div className={styles.field}>
              <label>Example Ratings</label>
              <div className={styles.ratingsTable}>
                {exampleCompanies.map(rating => (
                  <div key={rating.id} className={styles.ratingsRow}>
                    <input
                      type="text"
                      placeholder={!isFetching ? "Amazon" : "Loading..."}
                      value={rating.company}
                      onChange={(e) => handleCompanyChange(e, rating.id)}
                      className={`${styles.editableField}`}
                      style={{ maxWidth: '200px' }}
                    />
                    <select
                      value={rating.rating}
                      onChange={(e) => handleRatingChange(e, rating.id)}
                      className={`${styles.dropdown}`}
                      style={{ minWidth: '100px', maxWidth: '100px' }}
                    >
                      <option value="100">Great</option>
                      <option value="75">Good</option>
                      <option value="50">Neutral</option>
                      <option value="25">Poor</option>
                      <option value="0">Very Bad</option>
                    </select>
                    <input
                      type="text"
                      value={rating.explanation}
                      placeholder={!isFetching ? "Large well-funded company" : "Loading..."}
                      onChange={(e) => handleExplanationChange(e, rating.id)}
                      className={`${styles.editableField}`}
                      style={{ width: '50%' }}
                    />
                    <button className="btn" onClick={() => { setIsProfileEdited(true); handleRemove(rating.id) }}>
                      <i className="bi bi-trash" style={{ color: '#aaa' }}></i>
                    </button>
                  </div>
                ))}
              </div>
              <button className="btn btn-outline-secondary" style={{ marginTop: '10px' }} onClick={() => { setIsProfileEdited(true); handleAdd() }}>
                Add
              </button>
            </div>
          </div>
        </div>
        <div className={styles.section} style={{ display: activeTab === 'ratings' || window.innerWidth > 768 ? 'block' : 'none' }}>
          <div className={styles.globeContainer}>
            <img src="/globe.png" alt="Globe illustration" className={styles.globe} />
            <div className={styles.labels}>
              <div className={styles.scoreLabel}>Score a Company</div>
              <div className={styles.matchLabel}>See how well a lead matches with your ICP</div>
            </div>
          </div>
          <div className={styles.ratingField}>
            <input disabled={isScoring ? true : false} style={{ minWidth: '150px', maxWidth: '300px' }} type="text" placeholder='Nokia' value={toBeScoredCompanies} onChange={(e) => setToBeScoredCompanies(e.target.value)} />
            <button
              className={`btn btn-secondary ${styles.scoreButton}`}
              style={{ marginLeft: '10px' }}
              disabled={isScoring ? true : false}
              onClick={async () => {
                if (guid) {
                  setIsProfileEdited(false);
                  setIsScoring(true);
                  await score(toBeScoredCompanies.split(',').map(company => company.trim()), true);
                  setIsScoring(false);
                } else {
                  console.error('No guid!');
                  setError('No guid!');
                }
              }}
            >
              Score
            </button>
            <div className="spinner-border" style={{ height: '24px', width: '24px', minHeight: '24px', minWidth: '24px', marginLeft: '10px', color: '#aaa', opacity: isDiscovering || isScoring ? '1' : '0' }}></div>
            <button
              className={`btn ${styles.suggestButton}`}
              onClick={async () => {
                if (guid) {
                  discovery(guid);
                } else {
                  console.error('No guid!');
                  setError('No guid!');
                }
              }}
              style={{ marginLeft: '10px' }}
            >
              <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>Suggest Companies</span>
            </button>
          </div>
          {error && (
            <div style={{ minHeight: '50px' }} className={`${styles.inputSection} mt-3`}>
              <div className="invalid-feedback" style={{ display: 'block' }}>{error}</div>
            </div>
          )}
          {scores && scores.length > 0 && <>
            <div className={styles.scoredCompanies}>
              {scores.sort((a, b) => b.rating - a.rating).map((score, index) => (
                <div key={index} className={`${styles.scoredCompany} ${recentlyScored.includes(score.company) ? styles.recentlyScored : null}`}>
                  <div>
                    <img className={styles.scoredLogo} src={`https://logo.clearbit.com/${score.domain}`} alt={``} />
                  </div>
                  <div className={styles.scoredName}>
                    <a href={`https://${score.domain}`}>{score.company}</a>
                  </div>
                  <div className={styles.scoredScore} style={{ backgroundColor: getColorForRating(score.rating) }}>
                    {score.rating}
                  </div>
                  <div className={styles.scoredExplanation}>
                    {score.explanation}
                  </div>
                </div>
              ))}
              {isProfileEdited && (
                <div className={styles.overlay}>
                  <div className={styles.overlayContent}>
                    <label className={styles.overlayLabel}>The profile has changed</label>
                    <button className={styles.overlayButton}
                      onClick={async () => {
                        if (guid) {
                          setIsScoring(true);
                          await score(toBeScoredCompanies.split(',').map(company => company.trim()), false);
                          setIsProfileEdited(false);
                          setIsScoring(false);
                        } else {
                          console.error('No guid!');
                          setError('No guid!');
                        }
                      }}>Refresh scores</button>
                  </div>
                </div>
              )}
            </div>
            <div style={{ width: '100%', textAlign: 'left', marginTop: '20px' }}>
              <button
                className="btn btn-outline-secondary"
                onClick={async () => {
                  setScores([]);
                  setIsProfileEdited(false);
                  setShouldSaveData(true);
                }}
              >
                Clear All
              </button>
            </div>
          </>}
        </div>
      </div>
    </div>
  );
};

export default ICP;

