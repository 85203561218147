import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { fetchFromApi } from './utils/api';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import ICPMerger from './components/ICPMerger';
import Search from './components/Search';
import ICPTester from './components/ICPTester';
import ICP from './components/ICP';
import ICPBatch from './components/ICPBatch';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<ICP />} />
          <Route path="/profile/:guid" element={<ICP />} />
          <Route path="/merge" element={<ICPMerger />} />
          <Route path="/icp/:guid" element={<ICPTester />} />
          <Route path="/icp" element={<ICPTester />} />
          <Route path="/batch/:guid" element={<ICPBatch />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
