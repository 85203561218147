import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './ICPBatch.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchFromApi } from '../utils/api';

interface ExampleRating {
  id: number;
  company: string;
  rating: number;
  explanation: string;
}

interface Profile {
  companyName: string;
  companyDescription: string;
  goodCustomerDescription: string;
  badCustomerDescription: string;
  exampleCompanies: ExampleRating[];
}

const systemPrompt = `Your task is to rate companies based on their Ideal Customer Profile (ICP) fit.
The rating scale ranges from 0 to 99, where 0 indicates a poor fit and 99 indicates an excellent fit.
You will be provided with the name of the company seeking customers along with a short description.
You will be provided a short description of the ideal customer profile for the company.
You will be provided a short description of a bad customer profile for the company.
Additionally, you will be given a list of example target companies, their ratings, and explanations for their ratings.
Based on your knowledge of these companies and the provided information, rate a given target company.
Keep the explanations to 240 characters and focus on why this company matches with the information provided.
Return a JSON object containing the rating for the target company and their company website domain.
Example return object: {"company": "Amazon", "domain": "amazon.com", "rating": 25, "explanation": "They are too large."}
`;

const prompt = `We are: {mycompany}

What makes us unique:
{mycompany_description}

What makes an ideal customer:
{mycompany_ideal_customer}

What kind of customers should be avoided:
{mycompany_bad_customer}

Examples of how we rate potential customers:
{examples}

Rate the following company based on their Ideal Customer Profile (ICP) fit:
{company}
`;

const ICPBatch: React.FC = () => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [companiesToScore, setCompaniesToScore] = useState<string>('');
  const [scores, setScores] = useState<any[]>([]);
  const [progress, setProgress] = useState(0);
  const [isScoring, setIsScoring] = useState(false);
  const [error, setError] = useState('');

  const { guid } = useParams();

  useEffect(() => {
    if (guid) {
      loadProfile(guid);
    }
  }, [guid]);

  async function loadProfile(guid: string) {
    try {
      const response = await fetchFromApi(`/icp/${guid}`);
      const data = await response.json();
      setProfile(data);
      console.log('Loaded profile:', data);
    } catch (error) {
      console.error('Failed to load profile:', error);
      setError('Failed to load profile');
    }
  }

  async function score(companiesList: string[]) {
    const batchSize = 10;
    let scoredResults: any[] = [];

    setIsScoring(true);

    for (let i = 0; i < companiesList.length; i += batchSize) {
      const batch = companiesList.slice(i, i + batchSize);

      const scoringPromises = batch.map(company => {
        const finalPrompt = prompt
          .replace('{mycompany}', profile!.companyName)
          .replace('{mycompany_description}', profile!.companyDescription)
          .replace('{mycompany_ideal_customer}', profile!.goodCustomerDescription)
          .replace('{mycompany_bad_customer}', profile!.badCustomerDescription)
          .replace('{examples}', profile!.exampleCompanies.map((example) => `${example.company} - Rating: ${example.rating} - ${example.explanation}`).join('\n'))
          .replace('{company}', company);

        return fetchFromApi(`/gpt`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            system: systemPrompt,
            prompt: finalPrompt,
            response_format: 'json_object'
          })
        }).then(response => response.json())
          .then(data => ({ company: company, domain: data.result.domain, rating: data.result.rating, explanation: data.result.explanation }))
          .catch(error => ({ company: company, rating: 0, explanation: error.toString() }));
      });

      try {
        const results = await Promise.all(scoringPromises);
        console.log('Scoring results:', results);
        scoredResults = [...scoredResults, ...results];
        setScores(scoredResults);
        setProgress((i + batch.length) / companiesList.length * 100);
      } catch (error) {
        console.error('An error occurred while scoring companies:', error);
        setError('An error occurred while scoring companies');
      }
    }

    setIsScoring(false);
  }

  const handleScore = () => {
    const companiesList = companiesToScore.split('\n').map(company => company.trim()).filter(company => company.length > 0);
    if (companiesList.length > 0 && profile) {
      setScores([]);
      setProgress(0);
      score(companiesList);
    } else {
      setError('Please provide a valid list of companies and ensure the profile is loaded.');
    }
  };

  const handleExportToCSV = () => {
    const escapeCsvField = (field: string | undefined) => {
      if (field === undefined) {
        return '';
      }
      if (field.includes(',') || field.includes('"') || field.includes('\n') || field.includes(';')) {
        return `"${field.replace(/"/g, '""').replace(/;/g, '')}"`;
      }
      return field.replace(/;/g, '');
    };
  
    const csvContent = [
      ['Company', 'Domain', 'Rating', 'Explanation'],
      ...scores.map(score => [
        escapeCsvField(score.company),
        escapeCsvField(score.domain),
        escapeCsvField(score.rating !== undefined ? score.rating.toString() : ''),
        escapeCsvField(score.explanation)
      ])
    ]
      .map(row => row.join(','))
      .join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'scores.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <div className={styles.container}>
      {profile && (
        <>
          <h2 className={styles.companyName}>ICP: <b>{profile.companyName}</b> <span className={styles.profileGuid}>({guid})</span></h2>
          <div>Copy-paste list of companies</div>
          <textarea
            className={styles.textarea}
            placeholder="Company A&#10;Company B&#10;Company C&#10;..."
            value={companiesToScore}
            onChange={(e) => setCompaniesToScore(e.target.value)}
          />
          <button className={`btn btn-primary ${styles.button}`} onClick={handleScore} disabled={isScoring}>
            {isScoring ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: '7px' }}></span>
                Scoring...
              </>
            ) : 'Score'}
          </button>
          <div className={styles.progressContainer}>
            <div className={styles.progressBarContainer}>
              <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
            </div>
            <div className={styles.progressLabel}>
              {scores.length}/{companiesToScore.split('\n').filter(company => company.trim().length > 0).length}
            </div>
          </div>
          <button className={`btn btn-success ${styles.button}`} onClick={handleExportToCSV} disabled={scores.length === 0}>
            Export to CSV
          </button>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Company</th>
                <th>Domain</th>
                <th>Rating</th>
                <th>Explanation</th>
              </tr>
            </thead>
            <tbody>
              {scores.sort((a, b) => b.rating - a.rating).map((score, index) => (
                <tr key={index}>
                  <td>{score.company}</td>
                  <td>
                    {score.domain !== 'N/A' && <a href={`https://${score.domain}`}>{score.domain}</a>}
                  </td>
                  <td>{score.rating}</td>
                  <td style={{ textAlign: 'left' }}>{score.explanation}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {error && <div className={styles.error}>{error}</div>}
        </>
      )}
      {!profile && <div>Loading profile...</div>}
    </div>
  );
};

export default ICPBatch;
